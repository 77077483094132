import React from 'react';
import cn from 'classnames';

import { useAppState, useAppDispatch, PersonInfo } from 'src/Context';
import MakoAnalytics from 'src/components/common/MakoAnalytics';
import PersonModal from 'src/components/common/PersonModal';
import { useResponsive } from 'src/hooks/responsive';
import { useFiltered } from 'src/hooks/use-filtered';
import { updateCandlesAmount } from 'src/api';
import images from 'src/static/assets';
import { analytics } from 'src/utils';
import { CANDLES_AMOUNT_LS_KEY, PERSONS_CANDLES_LS_KEY } from 'src/constants';

import GridSkeleton from 'src/components/common/GridSkeleton';
import Persons from './Persons';
import Socials from '../Socials';
import InstagramPopup from 'src/components/common/InstagramPopup';

import css from './MainPage.module.scss';

const { desktopBg, mobileBg, makoLogo, whatsapp, intro, makoLogoSvg, makoLogoSvgMobile } = images;

/**
 * a - rank ?
 * b - first name
 * c - last name
 * d - age (number)
 * e - sex ?
 * f - place
 * g - symbol ?
 * h - age(d) + sub-text
 * i - age[d] + sub-text + place[f]
 * j - image link
 * k - unit
 * l - description
 */

const PAGE_SIZE = 100;

const MainPage = () => {
  const { filters, currentPage, isFetching, candles, title } = useAppState();
  const dispatch = useAppDispatch();
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const [isMobile] = useResponsive('MOBILE');
  const { items, total } = useFiltered({ pageSize: PAGE_SIZE });
  const [isAnimation, setIsAnimation] = React.useState<boolean>(false);
  const [isSticky, setIsSticky] = React.useState<boolean>(false);

  const statuses = [
    { value: '0,3', label: 'חטופים' }, // kidnapped
    { value: '1,2', label: 'שוחררו' }, // released
    { value: '2,3', label: `נרצחו` }, // killed
    { value: 'all', label: 'כולם' }, // all
  ];

  React.useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onScroll = () => {
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const onPersonCandleBtnClick = (person: PersonInfo) => {
    const currentCandlesAmount = window.localStorage.getItem(CANDLES_AMOUNT_LS_KEY);
    const currentPersonsCandles = JSON.parse(window.localStorage.getItem(PERSONS_CANDLES_LS_KEY) || '[]');

    if (!currentPersonsCandles.includes(person.id as string)) {
      window.localStorage.setItem(CANDLES_AMOUNT_LS_KEY, `${Number(currentCandlesAmount || 0) + 1}`);
      window.localStorage.setItem(PERSONS_CANDLES_LS_KEY, JSON.stringify([...currentPersonsCandles, person.id]));
      dispatch({ type: 'INCREASE_CANDLES_AMOUNT' });

      updateCandlesAmount();
    }
    dispatch({ type: 'SET_SELECTED_PERSON', payload: person });
    dispatch({ type: 'SET_IS_PERSON_MODAL_VISIBLE', payload: true });
  };

  const onSearchBtnClick = () => {
    if (!searchInputRef.current?.value) {
      return;
    }

    setIsAnimation(true);

    setTimeout(() => {
      setIsAnimation(false);
    }, 1000);

    dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 });
    dispatch({ type: 'SET_FILTERS', payload: { search: searchInputRef.current?.value || '' } });

    analytics.gtag.event('search', { CUSTOM_PARAMETER: searchInputRef.current?.value });
  };

  const onPrevClick = () => {
    dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage - 1 });
    scrollTop();
    analytics.gtag.event('previous page');
  };

  const onNextClick = () => {
    dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage + 1 });
    scrollTop();
    analytics.gtag.event('Next page');
  };

  const clickOnClear = () => {
    dispatch({ type: 'SET_FILTERS', payload: { search: '' } });
    dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 });

    if (searchInputRef.current) searchInputRef.current.value = '';
  };

  const isNextBtnDisabled = () => currentPage * PAGE_SIZE >= total;
  const isPaginationVisible = () => total > PAGE_SIZE;

  const onStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const status = statuses.find((item) => item.value === e.target.value);

    dispatch({ type: 'SET_FILTERS', payload: { status: e.target.value } });

    if (status) {
      analytics.gtag.event('sort', { CUSTOM_PARAMETER: status.label });
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (searchInputRef.current?.value) {
      onSearchBtnClick();
    }
  };

  const scrollTop = () => {
    const rootElement = document.documentElement;
    rootElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={css.pageWrapper}>
      <div className={css.fixedBg} style={{ backgroundImage: `url(${isMobile ? mobileBg : desktopBg})` }} />
      <header className={cn(css.header, isSticky && css.sticky)}>
        <a
          href="https://www.mako.co.il"
          target="_blank"
          rel="noopener noreferrer"
          className={css.makoLink}
          onClick={() => analytics.gtag.event('logo', { CUSTOM_PARAMETER: 'mako' })}
        >
          <img src={isMobile ? makoLogoSvgMobile : makoLogoSvg} alt="mako logo" />
        </a>
        <div className={css.title} onClick={clickOnClear}>
          <span>מחכים</span> לכם בבית
        </div>
        <Socials />
      </header>
      <div className={css.topSection}>
        <a href="https://www.mako.co.il" target="_blank" rel="noopener noreferrer" className={css.makoLink}>
          <img src={isMobile ? makoLogoSvgMobile : makoLogoSvg} alt="mako logo" />
        </a>

        <h1 className={css.title}>
          <img src={intro} alt="מחכים לכם בבית" />
        </h1>
        <p className={css.text} dangerouslySetInnerHTML={{ __html: title }} />
      </div>
      <div className={css.searchContainer}>
        <div className={cn(css.searchWrapper, searchInputRef.current?.value !== '' && css.isFocused)}>
          <div className={css.inputWrapper}>
            <form action="" onSubmit={onSubmit}>
              <input ref={searchInputRef} type="search" placeholder="חפשו לפי שם או יישוב" />
            </form>
            {searchInputRef.current?.value !== '' && <div className={css.clearBtn} onClick={clickOnClear}></div>}
          </div>

          <button className={cn(css.searchBtn)} onClick={onSearchBtnClick}>
            <svg className={css.searchIcon} width="19" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.65 0C2.989 0 0 2.989 0 6.65c0 3.661 2.989 6.65 6.65 6.65a6.61 6.61 0 0 0 4.345-1.633l.405.405V13.3l5.7 5.7 1.9-1.9-5.7-5.7h-1.228l-.405-.405A6.61 6.61 0 0 0 13.3 6.65C13.3 2.989 10.311 0 6.65 0Zm0 1.9a4.736 4.736 0 0 1 4.75 4.75 4.736 4.736 0 0 1-4.75 4.75A4.736 4.736 0 0 1 1.9 6.65 4.736 4.736 0 0 1 6.65 1.9Z"
                fill="#de0000"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className={css.statusContainer}>
        {statuses.map((item) => (
          <label key={item.value}>
            <input
              value={item.value}
              checked={filters.status === item.value}
              name="status"
              type="radio"
              onChange={onStatusChange}
            />
            <span>{item.label}</span>
          </label>
        ))}
      </div>

      {items.length === 0 && !isFetching && <div className={css.noSearchResults}>לא נמצאו תוצאות</div>}
      {items.length === 0 && isFetching && <GridSkeleton />}
      {items.length !== 0 && (
        <>
          <div className={css.itemsGrid}>
            <Persons showItems={items} isAnimation={isAnimation} onPersonCandleBtnClick={onPersonCandleBtnClick} />
          </div>
          {isPaginationVisible() && (
            <div className={css.btns}>
              <div className={cn(css.prevBtn, currentPage === 1 && css.disabled)} onClick={onPrevClick}>
                &lsaquo; לעמוד הקודם
              </div>
              <div className={cn(css.divider, (currentPage === 1 || isNextBtnDisabled()) && css.disabled)}></div>
              <div className={cn(css.nextBtn, isNextBtnDisabled() && css.disabled)} onClick={onNextClick}>
                לעמוד הבא &rsaquo;&nbsp;&nbsp;&nbsp;
              </div>
            </div>
          )}
        </>
      )}
      <div className={css.contact}>
        <a href="https://wa.me/972509595067" target="_blank" rel="noopener noreferrer" className={css.whatsappLink}>
          <img src={whatsapp} alt="whatsapp icon" />
          <span>להוספת מידע על החטופים כתבו לנו בוואטסאפ האדום של mako &rsaquo;</span>
        </a>
      </div>

      <footer>
        <a
          href="https://www.mako.co.il/help-5bb6bf4ccf39c110/Article-b7ff18a9de86471026.htm?partner=NewsfooterLinks"
          target="_blank"
          rel="noopener noreferrer"
          className={css.accessibilityLink}
        >
          הסדרי נגישות
        </a>
        <div className={css.credits}>
          Developed by{' '}
          <a href="https://www.rabbi.co.il/" target="_blank" rel="noopener noreferrer" className={css.rabbiLink}>
            Rabbi
          </a>
        </div>
      </footer>

      <PersonModal />
      <InstagramPopup />
      <MakoAnalytics vcmId="HP" />
    </div>
  );
};

export default MainPage;
